// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as ProjectWizardGeneralInformation from "../_components/ProjectWizardGeneralInformation/ProjectWizardGeneralInformation.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", $$Date.Naive.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Configuration = {
  decoder: decoder,
  fromJson: fromJson
};

function title(param) {
  return "Internet RFP Project";
}

function description(configurations) {
  return "The proposed Internet project has a projected service start date of " + (Belt_Array.map(configurations, (function (config) {
                    return $$Date.Naive.format(config.startDate, "dd MMM yyyy");
                  })).join(", ") + (" with a contract term of " + (Belt_Array.map(configurations, (function (config) {
                        return String(config.contractLength / 12 | 0);
                      })).join(", ") + " years.")));
}

var ProjectInfo = {
  title: title,
  description: description
};

function createProject(extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6, extra$7, extra$8, extra$9, extra$10) {
  return Api.createNewProject({
              TAG: "Internet",
              _0: "Rfp"
            }, extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6, extra$7, extra$8, extra$9, extra$10);
}

function saveDraftProjectAndContinueLater(extra) {
  return Api.saveInternetDraftProjectAndContinueLater("Internet", {
              TAG: "Internet",
              _0: "Rfp"
            }, extra);
}

var Api$1 = {
  saveDraft: Api.saveProjectGeneralInfoDraft,
  createProject: createProject,
  updateProject: Api.updateProject,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
};

function CreateInternetRfpProjectGeneralInformation(props) {
  var description$1 = props.description;
  var configurations = props.configurations;
  var title = props.title;
  var projectId = props.projectId;
  var title$1 = React.useMemo((function () {
          if (title !== undefined && title !== "") {
            return title;
          } else {
            return "Internet RFP Project";
          }
        }), [
        title,
        props.category
      ]);
  var description$2 = React.useMemo((function () {
          if (description$1 !== undefined && description$1 !== "") {
            return description$1;
          } else {
            return description(configurations);
          }
        }), [
        description$1,
        configurations
      ]);
  var navigationBackButtonLocation = React.useMemo((function () {
          if (projectId !== undefined) {
            return Routes_Project.Internet.Provider.editSelector(Caml_option.valFromOption(projectId));
          } else {
            return Routes_Project.Internet.Provider.newSelector;
          }
        }), [projectId]);
  return JsxRuntime.jsx(ProjectWizardGeneralInformation.make, {
              api: Api$1,
              userLoginStatus: props.userLoginStatus,
              userRole: props.userRole,
              projectId: projectId,
              title: title$1,
              description: description$2,
              alreadyUploadedDocuments: props.alreadyUploadedDocuments,
              businessName: props.businessName,
              businessContactName: props.businessContactName,
              businessEmail: props.businessEmail,
              businessPhone: props.businessPhone,
              headquartersAddress: props.headquartersAddress,
              contacts: props.contacts,
              isAgent: props.isAgent,
              agency: props.agency,
              agencyOptions: props.agencyOptions,
              canAgencyBeManaged: props.canAgencyBeManaged,
              navigationTotalSteps: 3,
              navigationBackButtonLocation: navigationBackButtonLocation,
              mobile: props.mobile,
              status: props.status
            });
}

var make = CreateInternetRfpProjectGeneralInformation;

export {
  Configuration ,
  ProjectInfo ,
  Api$1 as Api,
  make ,
}
/* decoder Not a pure module */
